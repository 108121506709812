import React, { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import {
  Box,
  HStack,
  VStack,
  Image,
  Link,
  List,
  ListItem,
} from '@chakra-ui/react';

import { Languages } from '@helpers/getLanguages';

import { InactivityTimerNavigate } from '@components';

import i18n from '../../../i18n';

import iconTarget from '@static/graphics/icon-target.svg';

const handlerChangeLanguage = ({ language }) => i18n.changeLanguage(language);

const LanguageSelector = () => {
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  const { locationId } = useParams();

  const activeLanguage = i18n.resolvedLanguage;

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== false || buttonsVisibility.isBackButtonVisible !== true) {
      setButtonsVisibility({ isLanguageButtonVisible: false, isBackButtonVisible: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box my="auto">
      <VStack overflowY="auto" maxHeight={96}>
        <List spacing={3}>
          {Languages.map(({ value, label }) => (
            <ListItem key={value}>
              <HStack>
                {activeLanguage === value && (<Image w={5} pt={1} src={iconTarget} />)}
                <Link
                  as="Button"
                  fontSize="4xl"
                  onClick={() => handlerChangeLanguage({ language: value })}
                  opacity={activeLanguage === value ? `1` : `0.4`}
                  style={{ textDecoration: `none` }}
                  pl={activeLanguage === value ? `0` : `7`}
                >
                  {label}
                </Link>
              </HStack>
            </ListItem>
          ))}
        </List>
      </VStack>
      <InactivityTimerNavigate timeout={10} navigateUrl={`/home/still-there/${locationId}`} />
    </Box>
  )
}

export default LanguageSelector
