import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  Image,
  Spacer
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import logoWihText from '@static/logos/logo-adidas-with-text.svg';
import gridBackGroundImage from '@static/background/grid-bg.png';
import rightSidebarGraphic from '@static/graphics/right-sidebar.svg';
import buttonLanguage from '@static/graphics/icon-language.svg';

const Layout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttonsVisibility, setButtonsVisibility] = useState({ isLanguageButtonVisible: false, isBackButtonVisible: false });

  return (
    <Box minHeight="100vh" backgroundImage={gridBackGroundImage} backgroundPosition="center">
      <Box minHeight="100vh">
        <Flex maxW="400px" marginX="auto" color="white">
          <Box w="30px">
          </Box>
          <Box w="340px">
            <Flex flexDirection="column" maxH="680px" height="100vh" justifyContent="center">
              <Box mt={8}>
                <Image ml="15px" src={logoWihText} />
              </Box>
                <Outlet context={[buttonsVisibility, setButtonsVisibility]} />
              <Box>
              <Flex px="2px" mb={8}>
                <Box>
                  {buttonsVisibility.isLanguageButtonVisible === true && (
                    <Button w="auto" variant="icon" onClick={() => navigate(`/home/language-selector`)}><Image src={buttonLanguage}></Image></Button>
                  )}
                </Box>
                <Spacer />
                <Box>
                  {buttonsVisibility.isBackButtonVisible === true && (
                    <Button w="auto" variant="default" onClick={() => navigate(-1)}>{t('common.back')}</Button>
                  )}
                </Box>
              </Flex>
              </Box>
            </Flex>
          </Box>
          <Box w="30px" align="right" pt={8}>
            <Image src={rightSidebarGraphic} />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default Layout;