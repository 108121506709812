export const colors = {
  brand: {
    colours: {
      primary: {
        0: '#ffffff',
      }
    },
    background: {
      primary: {
        0: `#d78438`,
        1: `#b7392a`,
      },
      secondary: {
        0: `#000000`,
      }
    }
  }
}
