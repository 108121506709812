import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'

import holdToPlayVideo from '@static/videos/4dfwd-hold-to-play.mp4';

const ScreenHoldToplay = ({ setAction }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoPlayer = useRef(null);

  useEffect(() => {
    switch (setAction) {
      case "reset":
        videoPlayer.current.seekTo(0);
        break;
      case "start":
        setVideoPlaying(true);
        break;
      case "stop":
        setVideoPlaying(false);
        break;
      default:
        // Unknown action.
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAction]);

  return (
    <ReactPlayer
      ref={videoPlayer}
      url={holdToPlayVideo}
      playing={videoPlaying}
      controls={false}
      muted={true}
      loop={false}
      width="100vw"
      height="100vh"
    />
  )
};

export default ScreenHoldToplay;