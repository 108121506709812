import React, { useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';

import { Heading, Image, Text, VStack, useConst } from '@chakra-ui/react';

import { PageWrapper, InactivityTimerNavigate } from '@components';

import { useTranslation } from 'react-i18next';

import graphicUnlocked from '@static/graphics/unlocked.png';

const currentDateTime = new Date();

const ContentUnlocked = () => {
  const redirectTime = useConst(() => currentDateTime.setSeconds(currentDateTime.getSeconds() + 3));
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  const { locationId } = useParams();
  
  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== false) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: false });
    }

    // Send request to change pattern on the device
    const messageBody = { locationId, locationScreen: "Idle", locationAction: "" };
    const apiSubDomain = `api-${window.location.hostname.split('.').shift()}`;

    fetch(`https://${apiSubDomain}.adidasdigital.com`, {
      method: `POST`,
      body: JSON.stringify(messageBody)
    })
      .then(response => response.json())
      .then(data => console.log(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTimer({ expiryTimestamp: redirectTime, onExpire: () => navigate(`/home/hold-to-play/${locationId}`, { replace: true }) });

  return (
    <PageWrapper>
      <VStack>
        <Text textAlign="left" w="full" fontWeight={100} lineHeight={4}>
          [{t('contentUnlocked.intro1')}
          <br />{t('contentUnlocked.intro2')}]:
          <br />+
          <br />+
          <br />+///
        </Text>
        <Image src={graphicUnlocked} py={5} />
        <Heading fontSize={`2xl`}>{t('contentUnlocked.heading')}</Heading>
      </VStack>
      <InactivityTimerNavigate timeout={10} navigateUrl={`/home/still-there/${locationId}`} />
    </PageWrapper>
  )
}

export default ContentUnlocked
