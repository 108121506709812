import React from 'react';
import {
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useTranslation, Trans } from 'react-i18next';

import bgImage from '@static/background/screen-experience-ended.jpg';
import graphic4dFwdLogo from '@static/graphics/adidas-4dfwd.svg';
import graphicHeader from '@static/graphics/forward-motion-redefined.svg';

const ScreenMessage = () => {
  const { t } = useTranslation();
  return (
    <VStack w="100vw" h="100vh" backgroundImage={bgImage} backgroundSize="cover">
      <Image src={graphic4dFwdLogo} w="80vw" mt="30vw" pb="5vw" />
      <Image src={graphicHeader}w="75vw" pb="5vw" />
      <Heading fontSize="8vw" w="75vw" pb="5vw">{t('screenMessage.heading')}</Heading>
      <Trans shouldUnescape>
        <Text fontSize="5vw" w="75vw">{t('screenMessage.text')}</Text>
      </Trans>
    </VStack>
  )
};

export default ScreenMessage;