import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Link, Heading, VStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { PageWrapper } from '@components';

import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== false || buttonsVisibility.isBackButtonVisible !== false) {
      setButtonsVisibility({ isLanguageButtonVisible: false, isBackButtonVisible: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <VStack>
        <Heading mt="100px" fontSize={`2xl`}>{t('default.heading')}</Heading>
        <Link
          href={t('default.link.url')}
          fontSize={`md`}
          isExternal>
          {t('default.link.text')}
          <ExternalLinkIcon boxSize="0.8rem" mx="5px" />
        </Link>
      </VStack>
    </PageWrapper>
  );
}

export default NotFound;
