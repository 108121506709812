import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import {
  Box,
} from '@chakra-ui/react';

const Welcome = lazy(() => import('@components/organisms/Welcome'));
const DrawToPlay = lazy(() => import('@components/organisms/DrawToPlay'));
const ContentUnlocked = lazy(() => import('@components/organisms/ContentUnlocked'));
const Messaging = lazy(() => import('@components/organisms/Messaging'));
const HoldToPlay = lazy(() => import('@components/organisms/HoldToPlay'));
const ExploreTheFuture = lazy(() => import('@components/organisms/ExploreTheFuture'));
const ExperienceInUse = lazy(() => import('@components/organisms/ExperienceInUse'));
const StillThere = lazy(() => import('@components/organisms/StillThere'));
const LanguageSelector = lazy(() => import('@components/organisms/LanguageSelector'));
const NotFound = lazy(() => import('@components/organisms/NotFound'));

const HomePage = () => {

  return (
    <Suspense fallback={<Box>Loading...</Box>}>
      <Routes>
        <Route path=":locationId" element={<Welcome />} />
        <Route path="draw-to-play/:locationId" element={<DrawToPlay />} />
        <Route path="content-unlocked/:locationId" element={<ContentUnlocked />} />
        <Route path="messaging/:locationId" element={<Messaging />} />
        <Route path="hold-to-play/:locationId" element={<HoldToPlay />} />
        <Route path="explore-the-future/:locationId" element={<ExploreTheFuture />} />
        <Route path="experience-in-use/:locationId" element={<ExperienceInUse />} />
        <Route path="still-there/:locationId" element={<StillThere />} />
        <Route path="language-selector/" element={<LanguageSelector />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default HomePage;
