import React, { useEffect } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';

import { Button, Heading, Image, Text, VStack } from '@chakra-ui/react';

import { PageWrapper } from '@components';

import { useTranslation, Trans } from 'react-i18next';

import graphic4dFwdLogo from '@static/graphics/adidas-4dfwd.svg';
import graphicHeader from '@static/graphics/forward-motion-redefined.svg';

const ExperienceInUse = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  const { locationId } = useParams();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== false) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <VStack>
        <Image src={graphic4dFwdLogo} my={4} />
        <Image src={graphicHeader} pb={3} w="220px" />
        <Heading pb={3} fontSize={`2xl`}>{t('experienceInUse.heading')}</Heading>
        <Trans shouldUnescape>
          <Text pb={3} fontSize={`md`}>{t('experienceInUse.text')}</Text>
        </Trans>
        <Button w="auto" variant="default" onClick={() => navigate(`/home/${locationId}`)}>{t('common.refresh')}</Button>
      </VStack>
    </PageWrapper>
  )
}

export default ExperienceInUse
