import { Global } from '@emotion/react'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'AdihausDINCn';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/AdihausDIN-CnMedium.woff2') format('woff2'),
             url('/fonts/AdihausDIN-CnMedium.woff') format('woff');
      }
      @font-face {
        font-family: 'AdihausDINCn';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/AdihausDIN-CnBold.woff2') format('woff2'),
             url('/fonts/AdihausDIN-CnBold.woff') format('woff');
      }
      `}
  />
)
