
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

const InactivityTimerNavigate = ({ timeout = 10, navigateUrl }) => {
  const navigate = useNavigate();

  const handleOnIdle = () => {
    navigate(navigateUrl);
  };

  useIdleTimer({
    timeout: timeout * 1000,
    onIdle: handleOnIdle,
  })

  return null
}

export default InactivityTimerNavigate
