import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useToast,
  Skeleton
} from '@chakra-ui/react';

const dbSubDomain = `db-${window.location.hostname.split('.').shift()}`;

const AdminStoreList = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [storeData, setStoreData] = useState();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordText = sessionStorage.getItem(`loginCredentials`);
    if (passwordText === null) {
      navigate(`/imahome-admin/login`);
    }

    // Get list of stores.
    fetch(`https://${dbSubDomain}.adidasdigital.com`, {
      method: `POST`,
      body: JSON.stringify({
        password: passwordText,
        command: `storeList`,
        payload: {}
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          setStoreData(data);
        } else {
          toast({
            title: `Error returning list of Stores`,
            description: `Please reload the page but if problem persists refer to the documentation.`,
            status: `error`,
            duration: 5000,
            isClosable: true,
            position: `top`
          })
        }
        setTimeout(() => {
          setIsPageLoaded(true);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setIsPageLoaded(true);
        }, 1000);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack
      flexDir="column"
      mb="2"
      justifyContent="center"
      alignItems="center"
    >
      <Heading>Control Panel - Store List</Heading>
      <Box minW={{ base: "90%", md: "468px" }}>
        <Skeleton isLoaded={isPageLoaded}>
          <Button
            variant="default"
            my="20px"
            onClick={() => navigate(`/imahome-admin/store-details/add`)}
          >
            Add Store
          </Button>
        </Skeleton>
        {typeof storeData !== `undefined` && (
          <StoreList storeData={storeData} isPageLoaded={isPageLoaded} />
        )}
      </Box>
    </Stack>
  )
}

const StoreList = ({ storeData, isPageLoaded }) => {
  const navigate = useNavigate();

  if (storeData.data.Count === 0) {
    return (
      <Text>There are no Stores</Text>
    );
  }

  return (
    <Skeleton isLoaded={isPageLoaded}>
      <TableContainer w="100%">
        <Table variant='simple'>
          <TableCaption>Store list</TableCaption>
          <Thead>
            <Tr>
              <Th fontSize={`20px`}>Store Name</Th>
              <Th fontSize={`20px`}>Store Contact</Th>
              <Th>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            {storeData.data.Items.map((store, index) => {
              const keyValue = store.StoreName.S + index;
              return (
                <Tr key={keyValue}>
                  <Td><p>{store.StoreName.S}</p></Td>
                  <Td>{store.StoreContact.S}</Td>
                  <Td textAlign="right">
                    <Button
                      variant="default"
                      onClick={() => navigate(`/imahome-admin/store-details/${store.StoreId.S}`)}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Skeleton>
  )
}

export default AdminStoreList;
