// May be able to move this to Locise once we get that setup
// but for now these will be hardcoded.
export const Languages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'fr',
    label: 'Français',
  },
];