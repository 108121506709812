import React from 'react';
import ReactPlayer from 'react-player'

import idleVideo from '@static/videos/4dfwd-idle.mp4';

const ScreenIdle = () => {
  return (
    <ReactPlayer
      url={idleVideo}
      playing={true}
      controls={false}
      muted={true}
      loop={true}
      width="100vw"
      height="100vh"
    />
  )
};

export default ScreenIdle;