import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Link,
  Select,
  useToast,
  Skeleton
} from '@chakra-ui/react';

import ShortUniqueId from 'short-unique-id';

import { Languages } from '@helpers/getLanguages';

const dbSubDomain = `db-${window.location.hostname.split('.').shift()}`;

const AdminLocationDetails = () => {
  const navigate = useNavigate();
  const { storeId, locationId } = useParams();
  const toast = useToast()
  // eslint-disable-next-line no-unused-vars
  const [pageAction, setPageAction] = useState(locationId);
  const [pageActionButtonText, setPageActionButtonText] = useState(`Add`);
  const [locationDetails, setLocationDetails] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [locationLanguage, setLocationLanguage] = useState('en');
  const [locationDescription, setLocationDescription] = useState('');
  const [isLocationNameError, setIsLocationNameError] = useState(true);
  const [currentHost, setCurrentHost] = useState(null);
  const [passwordText, setPasswordText] = useState('');
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordTextFromStorage = sessionStorage.getItem(`loginCredentials`);
    setPasswordText(passwordTextFromStorage);
    if (passwordTextFromStorage === null) {
      navigate(`/imahome-admin/login`);
    }
    if (pageAction !== `add`) {
      // Get store details and populate form
      setPageActionButtonText(`Update`);

      // Get details of Store.
      fetch(`https://${dbSubDomain}.adidasdigital.com`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordTextFromStorage,
          command: `locationGet`,
          payload: {
            locationId: pageAction,
            storeId: storeId
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            setLocationDetails(data.data.Item);
          } else {
            toast({
              title: `Error retrieving the Store details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          setTimeout(() => {
            setIsPageLoaded(true);
          }, 1000);
        })

    } else {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 1000);
    }
    if (typeof window !== `undefined`) {
      setCurrentHost(window.location.origin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(`locationDetails: `, locationDetails);
    if (typeof locationDetails !== `undefined` && locationDetails !== null) {
      setLocationName(locationDetails.LocationName.S);
      setIsLocationNameError(locationDetails.LocationName.S === ``);
      setLocationLanguage(locationDetails.LocationLanguage.S);
      setLocationDescription(locationDetails.LocationDescription.S);
    }
  }, [locationDetails]);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case `location-name`:
        setLocationName(e.target.value);
        setIsLocationNameError(e.target.value === ``);
        break;
      case `location-description`:
        setLocationDescription(e.target.value);
        break;
      case `location-language`:
        setLocationLanguage(e.target.value);
        break;
      default:
        console.error(`Invalid input element`);
    }
  };

  const handleLocationSave = () => {
    // Add/Update store details.
    let command = `locationUpdate`;
    let locationId = pageAction;
    if (pageAction === `add`) {
      command = `locationPut`;
      const uid = new ShortUniqueId({ length: 14, dictionary: `alphanum_lower` });
      locationId = uid();
    }

    // Get details of Store.
    return new Promise((resolve) => {
      fetch(`https://${dbSubDomain}.adidasdigital.com`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordText,
          command,
          payload: {
            locationId,
            storeId,
            locationName,
            locationLanguage,
            locationDescription,
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            toast({
              title: `Location '${locationName}' ${pageAction === `add` ? `Added` : `Updated`}`,
              description: `The Location details have been ${pageAction === `add` ? `Added` : `Updated`}`,
              status: `info`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          } else {
            toast({
              title: `Error saving Location details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          resolve();
          if (pageAction === `add`) {
            // redirect back to Store page when adding a Location.
            navigate(`/imahome-admin/store-details/${storeId}`);
          }
        })
    });
  };

  return (
    <Stack
      flexDir="column"
      mb="2"
      justifyContent="center"
      alignItems="center"
    >
      <Heading>Control Panel - {pageAction === `add` ? `Add Location` : `Update Location Details`}</Heading>
      <Box minW={{ base: "90%", md: "468px" }}>
        <Button
          variant="default"
          my="20px"
          onClick={() => navigate(`/imahome-admin/store-details/${storeId}`)}
        >
          Back to Store details page
        </Button>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isLocationNameError}>
            <FormLabel htmlFor='location-name'>Location name</FormLabel>
            <Input
              id="location-name"
              placeholder="Location name"
              onChange={handleInputChange}
              value={locationName}
            />
            <FormHelperText>
              Enter the name of the Location e.g. 'Store front'
            </FormHelperText>
            <FormErrorMessage>Location name is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl mb="20px">
            <FormLabel htmlFor='location-language'>Location language</FormLabel>
            <Select
              id="location-language"
              onChange={handleInputChange}
              defaultValue={locationLanguage}
            >
              {Languages.map(({ value, label }) => (
                <option value={value}>{label}</option>
              ))}
            </Select>
            <FormHelperText>
              Enter the language to display text
            </FormHelperText>
          </FormControl>
        </Skeleton>
        <Skeleton isLoaded={isPageLoaded}>
          <FormControl mb="20px">
            <FormLabel htmlFor='location-description'>Location description</FormLabel>
            <Input
              id="location-description"
              placeholder="Location description"
              onChange={handleInputChange}
              value={locationDescription}
            />
            <FormHelperText>
              Enter the description of the Location
            </FormHelperText>
          </FormControl>
        </Skeleton>
        <Skeleton isLoaded={isPageLoaded}>
          <Button
            variant="default"
            mr="20px"
            onClick={() => navigate(`/imahome-admin/store-details/${storeId}`)}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            alignSelf="start"
            onClick={() => handleLocationSave()}
            isDisabled={isLocationNameError}
          >
            {pageActionButtonText}
          </Button>
        </Skeleton>
        {pageAction !== `add` &&
          <Skeleton isLoaded={isPageLoaded}>
            <Heading as="h3" my="20px" textAlign="left" fontSize="2xl">Barcode Page</Heading>
            <Link href={`${currentHost}/barcode/${locationId}`} isExternal>
              {currentHost}/barcode/{locationId}
            </Link>
            <Heading as="h3" my="20px" textAlign="left" fontSize="2xl">Display Page</Heading>
            <Link href={`${currentHost}/display/${locationId}/${locationLanguage}`} isExternal>
              {currentHost}/display/{locationId}/{locationLanguage}
            </Link>
          </Skeleton>
        }
      </Box>
    </Stack>
  )
}

export default AdminLocationDetails;
