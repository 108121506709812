
import { Box, Flex, Image } from '@chakra-ui/react';

import panelBackGroundImage from '@static/background/panel-bg.png';
import graphicbar from '@static/graphics/bar.gif';
import breakingForces from '@static/graphics/breaking-forces.gif';
import forwardMotion from '@static/graphics/forward-motion.gif';

const PageWrapper = ({ children }) => {
  return (
    <Box my="auto">
      <Image w={40} position="fixed" top={12} left="calc(50% - 190px)" opacity={0.4} src={breakingForces} />
      <Image w={44} position="fixed" top="460px" left="calc(50% + 20px)" opacity={0.4} src={forwardMotion} />
      <Box w="340px" h="455px" mx="auto" p="40px 15px 20px 15px" backdropFilter="auto" backdropBlur="2px" backgroundImage={panelBackGroundImage} backgroundSize="cover">
        <Flex flexDirection="column" h="395px">
          <Box flex="1" pt={3}>
            {children}
          </Box>
          <Box>
            <Image h={7} mx="auto" bottom="0" src={graphicbar} />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default PageWrapper
