import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Center, Image } from '@chakra-ui/react';

import graphic4dFwdLogo from '@static/graphics/adidas-4dfwd.svg';
import graphicHeader from '@static/graphics/forward-motion-redefined.svg';

const Layout = () => {
  const [buttonsVisibility, setButtonsVisibility] = useState({ isLanguageButtonVisible: false, isBackButtonVisible: false });

  return (
    <Box
      minHeight="100vh"
      bgColor="#000000"
    >
      <Center pt={8}>
        <Image src={graphic4dFwdLogo} width={40} mb={3} />
      </Center>
      <Center>
        <Image src={graphicHeader} width={40} mb={3} />
      </Center>
      <Box>
        <Outlet context={[buttonsVisibility, setButtonsVisibility]} />
      </Box>
    </Box>
  )
}

export default Layout;