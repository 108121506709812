import React, { useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';

import { Button, Heading, Text, VStack } from '@chakra-ui/react';

import { PageWrapper } from '@components';

import { useTranslation } from 'react-i18next';

const StillThere = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentDateTime = new Date();
  
  // eslint-disable-next-line no-unused-vars
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();
  const redirectTime = currentDateTime.setSeconds(currentDateTime.getSeconds() + 10);

  const { locationId } = useParams();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== true) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: true });
    }

    // Add class on body so that a dark background is used.
    document.body.classList.add(`body__background--dark`);

    return () => {
      // Remore class on body so that a dark background is not used.
      document.body.classList.remove(`body__background--dark`);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    seconds
  } = useTimer({ expiryTimestamp: redirectTime, onExpire: () => navigate(`/home/explore-the-future/${locationId}`) });

  return (
    <PageWrapper variant="dark">
      <VStack>
        <Text textAlign="left" w="full" fontWeight={100} lineHeight="14px">
          [{t('stillThere.intro1')}
          <br />{t('stillThere.intro2')}]:
          <br />+
          <br />+
          <br />+///
        </Text>
        <Heading py={6} fontSize={`2xl`}>{t('stillThere.heading')}</Heading>
        <Text fontSize={`6xl`} pb={8}>{seconds}</Text>
        <Button w="auto" variant="default" onClick={() => navigate(-1)}>{t('common.yes')}</Button>
      </VStack>
    </PageWrapper>
  )
}

export default StillThere
