import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Button, Heading, Image, Text, VStack } from '@chakra-ui/react';

import { PageWrapper } from '@components';

import { useTranslation, Trans } from 'react-i18next';

import graphic4dFwdLogo from '@static/graphics/adidas-4dfwd.svg';
import graphicHeader from '@static/graphics/forward-motion-redefined.svg';

const Landing = () => {
  const { t } = useTranslation();
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== false || buttonsVisibility.isBackButtonVisible !== false) {
      setButtonsVisibility({ isLanguageButtonVisible: false, isBackButtonVisible: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <VStack>
        <Image src={graphic4dFwdLogo} my={4} />
        <Image src={graphicHeader} pb={3} w="220px" />
        <Heading pb={3} fontSize={`2xl`}>{t('landing.heading')}</Heading>
        <Trans shouldUnescape>
          <Text pb={3} fontSize={`md`}>{t('landing.text')}</Text>
        </Trans>
        <Button as="a" w="auto" target="_blank" variant="default" href={t('common.sign-up-url')}>{t('common.sign-up')}</Button>
      </VStack>
    </PageWrapper>
  )
}

export default Landing;
