import React from 'react';
import Helmet from 'react-helmet';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import {
  Heading,
  Text,
  ChakraProvider,
} from '@chakra-ui/react';

import { theme } from './styles/theme';
import { Fonts } from './styles/fonts';

import { Landing, Layout, LayoutAdmin } from '@components';
import NoRoutePage from '@pages/NoRoute';
import HomePage from '@pages/Home';
import DisplayPage from '@pages/Display';
import BarcodePage from '@pages/Barcode';
import AdminHomePage from '@pages/admin/AdminHome';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Adidas - 4D FWD</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <Fonts />
      <BrowserRouter>
        <Routes>
          <Route path="home" element={<Layout />}>
            <Route index element={<NoRoutePage />} />
            <Route path="*" element={<HomePage />} />
          </Route>
          <Route path="display/:locationId/:locationLanguage" element={<DisplayPage />} />
          <Route path="barcode/:locationId" element={<BarcodePage />} />
          <Route path="imahome-admin" element={<LayoutAdmin />}>
            <Route index element={<><Heading>404</Heading><Text>The page you are looking has not been found</Text></>} />
            <Route path="*" element={<AdminHomePage />} />
          </Route>
          <Route path="*" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="*" element={<NoRoutePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
