import React, { useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

import { Button, Heading, Text, VStack } from '@chakra-ui/react';

import { PageWrapper, InactivityTimerNavigate } from '@components';

import { useTranslation } from 'react-i18next';

const Messaging = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  const { locationId } = useParams();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== true) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: true });
    }
    
    // Send request to change pattern on the device
    const messageBody = { locationId, locationScreen: "Idle", locationAction: "" };
    const apiSubDomain = `api-${window.location.hostname.split('.').shift()}`;

    fetch(`https://${apiSubDomain}.adidasdigital.com`, {
      method: `POST`,
      body: JSON.stringify(messageBody)
    })
      .then(response => response.json())
      .then(data => console.log(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <VStack>
        <Text textAlign="left" w="full" fontWeight={100} lineHeight="14px">
          [{t('messaging.intro1')}
          <br />{t('messaging.intro2')}]:
          <br />+
          <br />+
          <br />+///
        </Text>
        <Heading py={6} fontSize={`2xl`}>{t('messaging.heading')}</Heading>
        <Button w="auto" variant="default" onClick={() => navigate(`/home/content-unlocked/${locationId}`)}>{t('common.continue')}</Button>
      </VStack>
      <InactivityTimerNavigate timeout={10} navigateUrl={`/home/still-there/${locationId}`} />
    </PageWrapper>
  )
}

export default Messaging
