import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  Skeleton
} from '@chakra-ui/react';

import ShortUniqueId from 'short-unique-id';

const dbSubDomain = `db-${window.location.hostname.split('.').shift()}`;

const AdminStoreDetails = () => {
  const navigate = useNavigate();

  const { storeId } = useParams();
  const toast = useToast()
  // eslint-disable-next-line no-unused-vars
  const [pageAction, setPageAction] = useState(storeId);
  const [pageActionButtonText, setPageActionButtonText] = useState(`Add`);
  const [storeDetails, setStoreDetails] = useState(null);
  const [locationsData, setLocationsData] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [isStoreNameError, setIsStoreNameError] = useState(true);
  const [storeContact, setStoreContact] = useState('');
  const [isStoreContactError, setIsStoreContactError] = useState(true);
  const [storeAddress, setStoreAddress] = useState('');
  const [isStoreAddressError, setIsStoreAddressError] = useState(true);
  const [passwordText, setPasswordText] = useState('');
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // Get password from session.
    const passwordTextFromStorage = sessionStorage.getItem(`loginCredentials`);
    setPasswordText(passwordTextFromStorage);
    if (passwordTextFromStorage === null) {
      navigate(`/imahome-admin/login`);
    }

    if (pageAction !== `add`) {
      setPageActionButtonText(`Update`);
      // Get details of Store.
      fetch(`https://${dbSubDomain}.adidasdigital.com`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordTextFromStorage,
          command: `storeGet`,
          payload: {
            storeId: pageAction
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            setStoreDetails(data.data.Item);
          } else {
            toast({
              title: `Error retrieving the Store details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
        })

      // Get list of locations.
      fetch(`https://${dbSubDomain}.adidasdigital.com`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordTextFromStorage,
          command: `locationList`,
          payload: {
            storeId: pageAction
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            setLocationsData(data);
          } else {
            toast({
              title: `Error returning list of Locations`,
              description: `Please reload the page but if problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
        })
    } else {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof storeDetails !== `undefined` && storeDetails !== null) {
      setStoreName(storeDetails.StoreName.S);
      setIsStoreNameError(storeDetails.StoreName.S === ``);
      setStoreContact(storeDetails.StoreContact.S);
      setIsStoreContactError(storeDetails.StoreContact.S === ``);
      setStoreAddress(storeDetails.StoreAddress.S);
      setIsStoreAddressError(storeDetails.StoreAddress.S === ``);
    }
    setTimeout(() => {
      setIsPageLoaded(true)
    }, 1000);
  }, [storeDetails]);

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case `store-name`:
        setStoreName(e.target.value);
        setIsStoreNameError(e.target.value === ``);
        break;
      case `store-contact`:
        setStoreContact(e.target.value);
        setIsStoreContactError(e.target.value === ``);
        break;
      case `store-address`:
        setStoreAddress(e.target.value);
        setIsStoreAddressError(e.target.value === ``);
        break;
      default:
        console.error(`Invalid input element`);
    }
  };

  const handleStoreSave = () => {
    // Add/Update store details.
    let command = `storeUpdate`;
    let storeId = pageAction;
    if (pageAction === `add`) {
      command = `storePut`;
      const uid = new ShortUniqueId({ length: 14, dictionary: `alphanum_lower` });
      storeId = uid();
    }

    // Get details of Store.
    return new Promise((resolve) => {
      fetch(`https://${dbSubDomain}.adidasdigital.com`, {
        method: `POST`,
        body: JSON.stringify({
          password: passwordText,
          command,
          payload: {
            storeId,
            storeName,
            storeContact,
            storeAddress,
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true) {
            toast({
              title: `Store '${storeName}' ${pageAction === `add` ? `Added` : `Updated`}`,
              description: `The store details have been ${pageAction === `add` ? `Added` : `Updated`}`,
              status: `info`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          } else {
            toast({
              title: `Error saving Store details`,
              description: `Please reload the page but if the problem persists refer to the documentation.`,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          resolve();
          if (pageAction === `add`) {
            // redirect back to Store page when adding a Location.
            navigate(`/imahome-admin/store-list`);
          }
        })
    });
  };

  return (
    <Stack
      flexDir="column"
      mb="2"
      justifyContent="center"
      alignItems="center"
    >
      <Heading>Control Panel - {pageAction === `add` ? `Add Store` : `Update Store Details`}</Heading>
      <Box minW={{ base: "90%", md: "468px" }}>
        <Button
          variant="default"
          my="20px"
          onClick={() => navigate(`/imahome-admin/store-list`)}
        >
          Back to Stores page
        </Button>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isStoreNameError}>
            <FormLabel htmlFor='store-name'>Store name</FormLabel>
            <Input
              id="store-name"
              placeholder="Store name"
              onChange={handleInputChange}
              value={storeName}
            />
            <FormHelperText>
              Enter the name of the Store e.g. 'UK - Flagship Store London'
            </FormHelperText>
            <FormErrorMessage>Store name is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isStoreContactError}>
            <FormLabel htmlFor='store-contact'>Store contact</FormLabel>
            <Input
              id="store-contact"
              placeholder="Store contact"
              onChange={handleInputChange}
              value={storeContact}
            />
            <FormHelperText>
              Enter the name of the Store Contact e.g. 'Frank Dassler'
            </FormHelperText>
            <FormErrorMessage>Store contact is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <FormControl isRequired mb="20px" isInvalid={isStoreAddressError}>
            <FormLabel htmlFor='store-address'>Store address</FormLabel>
            <Input
              id="store-address"
              placeholder="Store address"
              onChange={handleInputChange}
              value={storeAddress}
            />
            <FormHelperText>
              Enter the address of the Store
            </FormHelperText>
            <FormErrorMessage>Store address is required.</FormErrorMessage>
          </FormControl>
        </Skeleton>

        <Skeleton isLoaded={isPageLoaded}>
          <Button
            variant="default"
            mr="20px"
            onClick={() => navigate(`/imahome-admin/store-list`)}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            type="submit"
            alignSelf="start"
            onClick={() => handleStoreSave()}
            isDisabled={isStoreNameError || isStoreContactError || isStoreAddressError}
          >
            {pageActionButtonText}
          </Button>
        </Skeleton>
        {pageAction !== `add` && <LocationList locationData={locationsData} storeId={pageAction} isPageLoaded={isPageLoaded} />}
      </Box>
    </Stack>
  )
}

const LocationList = ({ locationData, storeId, isPageLoaded }) => {
  const navigate = useNavigate();

  return (
    <>
      <Heading mt="40px" mb="20px">Location list</Heading>
      <Button
        variant="default"
        my="20px"
        onClick={() => navigate(`/imahome-admin/location-details/${storeId}/add`)}
      >
        Add Location
      </Button>
      {locationData?.data?.Count > 0 ?
        <Skeleton isLoaded={isPageLoaded}>
          <TableContainer w="100%">
            <Table variant='simple'>
              <TableCaption>Location list</TableCaption>
              <Thead>
                <Tr>
                  <Th fontSize={`20px`}>Location Name</Th>
                  <Th>&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>

                {locationData.data.Items.map((location, index) => {
                  const keyValue = location.LocationName + index;
                  return (
                    <Tr key={keyValue} width="100%">
                      <Td><p>{location.LocationName.S}</p></Td>
                      <Td textAlign="right">
                        <Button
                          variant="default"
                          onClick={() => navigate(`/imahome-admin/location-details/${storeId}/${location.LocationId.S}`)}
                        >
                          Edit
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Skeleton>
        :
        <Text>There are no Locations</Text>
      }
    </>
  )
}

export default AdminStoreDetails;
