import { Routes, Route } from 'react-router-dom';
import { Box, Heading, Text } from '@chakra-ui/react';

import { AdminLogin, AdminStoreList, AdminStoreDetails, AdminLocationDetails } from '@components';

const AdminHomePage = () => {
  return (
    <Box>
      <Routes>
        <Route path="login" element={<AdminLogin />} />
        <Route path="store-list" element={<AdminStoreList />} />
        <Route path="store-details/:storeId" element={<AdminStoreDetails />} />
        <Route path="location-details/:storeId/:locationId" element={<AdminLocationDetails />} />
        <Route path="*" element={<><Heading>404</Heading><Text>The page you are looking has not been found</Text></>} />
      </Routes>
    </Box>
  )
}

export default AdminHomePage
