import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  chakra,
  useToast,
} from '@chakra-ui/react';

import { FaLock } from 'react-icons/fa';

const CFaLock = chakra(FaLock);


const AdminLoginPanel = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [passwordText, setPasswordText] = useState(``);

  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  const handlePasswordChange = (e) => setPasswordText(e.target.value);

  const handleLogin = () => {
    // Login logic goes here.
    setIsFormSubmitting(true);

    const apiSubDomain = `auth-${window.location.hostname.split('.').shift()}`;

    return new Promise((resolve) => {
      fetch(`https://${apiSubDomain}.adidasdigital.com`, {
        method: `POST`,
        body: JSON.stringify({ password: passwordText })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if (data.success === true) {
            sessionStorage.setItem(`loginCredentials`, passwordText);
            navigate(`/imahome-admin/store-list`, { replace: true });
          } else {
            toast({
              title: `Login failed`,
              description: data.error,
              status: `error`,
              duration: 5000,
              isClosable: true,
              position: `top`
            })
          }
          setIsFormSubmitting(false);
          resolve();
        })
        .catch(() => {
          toast({
            title: `Login failed`,
            description: `Error accessing the authenticator`,
            status: `error`,
            duration: 5000,
            isClosable: true,
            position: `top`
          })
          setIsFormSubmitting(false);
          resolve();
        })
    });
  };

  const handleKeyUp = (event) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      handleLogin();
    }
  }
  return (
    <Stack
      flexDir="column"
      mb="2"
      justifyContent="center"
      alignItems="center"
    >
      <Heading>Control Panel</Heading>
      <Box minW={{ base: "100%", sm: "468px" }}>
        <Stack
          spacing={4}
          p="1rem"
        >
          <FormControl>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                children={<CFaLock color="gray.300" />}
              />
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={handlePasswordChange}
                onKeyUp={(event) => handleKeyUp(event)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" variant="showHide" onClick={handleShowPasswordClick}>
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
            variant="default"
            type="submit"
            width="full"
            onClick={() => handleLogin()}
            isDisabled={passwordText === ``}
          >
            Login
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}

export default AdminLoginPanel;
