export const components = {
  Heading: {
    baseStyle: {
      fontFamily: `brand.fonts.default`,
      color: `brand.colours.primary.0`,
      fontWeight: 700,
      margin: `0 auto`,
      textTransform: `uppercase`,
      textAlign: `center`,
      px: `5`,
    },
  },
  Text: {
    baseStyle: {
      fontFamily: `brand.fonts.default`,
      color: `brand.colours.primary.0`,
      fontWeight: 700,
      textTransform: `uppercase`,
      margin: `0 auto`,
      textAlign: `center`
    },
  },
  Input: {
    baseStyle: {
      field: {
        _placeholder: {
          color: `rgba(255, 255, 255, 0.5)`,
        },
      },
    },
  },
  Button: {
    baseStyle: {
      margin: `0px`,
      textTransform: `uppercase`,
      fontWeight: 700,
      boxShadow: `0px 1px 1px rgba(255, 255, 255, 0.25), 0px 0px 3px brand.colours.primary.0`,
      backgroundColor: `rgba(0, 0, 0, 0.12)`,
      borderRadius: `2px`,
      borderWidth: `1px`,
      borderStyle: `solid`,
      borderColor: `brand.colours.primary.0`,
      backdropFilter: `blur(3px)`,
      fontSize: `sm`,
      _hover: {
        background: `rgba(0, 0, 0, 0.2)`,
      },
    },
    variants: {
      'default': {
        paddingX: `5`,
        minW: `100px`,
      },
      'showHide': {
        fontSize: `xs`,
      },
      'icon': {
        width: `10`,
        height: `10`,
        padding: `0px`,
        margin: `0px`,
      },
      'hold': {
        width: `32`,
        height: `32`,
        backgroundColor: `#bf9683`,
        fontSize: `5xl`,
        borderStyle: `solid`,
        borderWidth: `1`,
        borderColor: `0`,
        borderRadius: `full`,
        _hover: {
          background: `#bf9683`,
        },
      }
    }
  }
};
