import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

const BarcodePage = () => {
  const params = useParams();
  const [currentHost, setCurrentHost] = useState(null);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setCurrentHost(window.location.origin);
    }
  }, []);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#fff"
      justifyContent="center"
      alignItems="center"
    >
      <Box w="75%" h="75%">
        <QRCodeSVG size="100%" value={`${currentHost}/home/${params.locationId}`} />
      </Box>
    </Flex>
  )
}

export default BarcodePage;
