import React, { useEffect, useState, useCallback } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';

import { Box, Image, Text, HStack, VStack } from '@chakra-ui/react';

import PatternLock from "react-pattern-lock";

import { PageWrapper, InactivityTimerNavigate } from '@components';

import { useTranslation } from 'react-i18next';

import arrowImage from '@static/graphics/arrow.png';
import guideBackground from '@static/background/guide-bg.svg';
import './pattern-lock.css';

const DrawToPlay = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [patternPath, setPatternPath] = useState([]);
  const [isPatternDisabled, setIsPatternDisabled] = useState(false);
  const [isPatternValid, setIsPatternValid] = useState(false);

  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  const { locationId } = useParams();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== true) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: true });
    }

    // Send request to change pattern on the device
    const messageBody = { locationId, locationScreen: "Idle", locationAction: "" };
    const apiSubDomain = `api-${window.location.hostname.split('.').shift()}`;

    fetch(`https://${apiSubDomain}.adidasdigital.com`, {
      method: `POST`,
      body: JSON.stringify(messageBody)
    })
      .then(response => response.json())
      .then(data => console.log(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPatternValid === true) {
      navigate(`/home/messaging/${locationId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatternValid]);

  const reset = useCallback(() => {
    // Renable the pattern
    setIsPatternDisabled(false);
    // Clear the pattern.
    setPatternPath([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatternDisabled]);

  const processPattern = useCallback(() => {
    const formattedPattern = patternPath.join(`-`);
    if (formattedPattern === `0-3-6-7-8`) {
      setIsPatternValid(true);
    } else {
      setIsPatternDisabled(true);
      setTimeout(reset, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patternPath]);

  return (
    <PageWrapper>
      <VStack align="start">
        <Text mb={3} textAlign="left" w="full" fontWeight={100} lineHeight={4}>
          [{t('drawToPlay.intro')}]:
          <br />+
          <br />+
          <br />+///
        </Text>
        <HStack>
          <Box w="30px">
            <HStack transform="translateX(-100px) rotate(90deg)" w="240px">
              <Text pr="20px" fontSize="11px" fontWeight="medium" opacity="0.8" whiteSpace="nowrap">
                [{t('drawToPlay.down')}]:
              </Text>
              <Image src={arrowImage} align="left" />
            </HStack>
          </Box>
          <Box w="240px" h="240px">
            <Image
              src={guideBackground}
              align="left"
              position="absolute"
              pt="34px"
              pl="35px"
              animation="blink-animation 2s steps(7, start) infinite"
            />
            <PatternLock
              path={patternPath}
              width={240}
              pointSize={25}
              connectorThickness={2}
              size={3}
              disabled={isPatternDisabled}
              // onchange is called every time a point is touched.
              onChange={(pathPatternLock) => setPatternPath(pathPatternLock)}
              // We disable the pattern lock when the user finishes drawing a pattern so they can no longer modify it.
              onFinish={(pattern) => {
                processPattern();
              }}
              style={{
                margin: "0 auto"
              }}
            />
          </Box>
          <Box w="30px">
          </Box>
        </HStack>
        <HStack>
          <Text px="20px" fontSize="11px" fontWeight="medium" opacity="0.8">
            [{t('drawToPlay.across')}]:
          </Text>
          <Image src={arrowImage} align="left" />
        </HStack>
      </VStack>
      <InactivityTimerNavigate timeout={1000000} navigateUrl={`/home/still-there/${locationId}`} />
    </PageWrapper>
  )
}

export default DrawToPlay
