export const styles = {
  global: {
    html: {
      fontSize: `16px`
    },
    body: {
      color: `brand.colours.primary.0`,
      fontFamily: `brand.fonts.default`,
      bgGradient: `radial(farthest-corner at 100% 0, brand.background.primary.0 0, brand.background.primary.1 100%)`,
      '.dark-background': {
        bgGradient: `radial(farthest-corner at 100% 0, #666666 0, #000000 100%)`
      }
    },
    'body.body__background--dark': {
      bgGradient: `radial(farthest-corner at 100% 0, #666666 0, #000000 100%)`
    },
    '@keyframes blink-animation': {
      to: {
        visibility: `hidden`
      }
    }
  }
}
