import React, { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { Button, Heading, Image, Text, VStack } from '@chakra-ui/react';

import { PageWrapper } from '@components';

import { useTranslation, Trans } from 'react-i18next';

import graphic4dFwdLogo from '@static/graphics/adidas-4dfwd.svg';
import graphicHeader from '@static/graphics/forward-motion-redefined.svg';

const ExploreTheFuture = () => {
  const { t } = useTranslation();
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();

  const { locationId } = useParams();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== false) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: false });
    }

    // Send request to change pattern on the device
    const messageBody = { locationId, locationScreen: "Message", locationAction: "" };
    const apiSubDomain = `api-${window.location.hostname.split('.').shift()}`;

    fetch(`https://${apiSubDomain}.adidasdigital.com`, {
      method: `POST`,
      body: JSON.stringify(messageBody)
    })
      .then(response => response.json())
      .then(data => console.log(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <VStack>
        <Image src={graphic4dFwdLogo} my={4} />
        <Image src={graphicHeader} pb={3} w="220px" />
        <Heading pb={3} fontSize={`2xl`}>{t('exploreTheFuture.heading')}</Heading>
        <Trans shouldUnescape>
          <Text pb={3} fontSize={`md`}>{t('exploreTheFuture.text')}</Text>
        </Trans>
        <Button as="a" w="auto" target="_blank" variant="default" href={t('common.sign-up-url')}>{t('common.sign-up')}</Button>
      </VStack>
    </PageWrapper>
  )
}

export default ExploreTheFuture
